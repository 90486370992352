import React from 'react'
import Faqs from '../components/common/FAQ'
import RequestChargepointCTA from '../components/cta/RequestChargepointCTA'
import Layout from '../components/layout'
import Seo from '../components/seo'

class RootIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo
          title="Frequently Asked Questions | Download on iOS or Android | Urban Fox"
          description="Get to know Urban Fox and how we can help you charge your electric vehicle with ease. Download our app today."
        />
        <Faqs filter="EV Drivers" />
        <RequestChargepointCTA />
      </Layout>
    )
  }
}

export default RootIndex
