import React from 'react'
import Button from '../common/Button'

export default function RequestChargepointCTA() {
  return (
    <div className="bg-ufblack">
      <div className="mx-auto max-w-7xl px-6 pt-12 md:pt-24 lg:flex lg:items-center lg:justify-between lg:px-8">
        <div className="flex flex-col space-y-4">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Request a public EV chargepoint
          </h2>
          <p className="mt-4 max-w-3xl text-lg text-white">
            Tired of looking for an “EV charge point near me”? Request a public
            EV chargepoint near your home and we’ll pass it onto the relevant
            local authority.
          </p>
        </div>
        <div className="mt-10 flex flex-col md:flex-row items-start md:items-center gap-6 lg:mt-0 lg:flex-shrink-0">
          <Button label="Request a chargepoint" href="/request-chargepoint" />
          <Button label="Get our app" href="/app" />
        </div>
      </div>
    </div>
  )
}
