import { BLOCKS } from '@contentful/rich-text-types'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import get from 'lodash/get'
import React from 'react'

export default function FAQ({ filter }) {
  const data = useStaticQuery(graphql`
    query FAQQuery {
      allContentfulFaq(sort: { fields: [order], order: ASC }) {
        nodes {
          question
          order
          answer {
            raw
          }
          displayLocation
        }
      }
    }
  `)
  let faqs = get(data, 'allContentfulFaq.nodes', null)

  if (filter) {
    faqs = faqs.filter(
      (faq) =>
        faq.displayLocation === filter || faq.displayLocation === 'Everywhere'
    )
  }

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { gatsbyImage } = node.data.target
        return (
          <GatsbyImage image={getImage(gatsbyImage)} alt={'Answer image'} />
        )
      },
    },
  }

  return (
    <div className="bg-ufblack">
      <div className="mx-auto max-w-7xl px-6 py-24 lg:px-8">
        <div className="mx-auto max-w-4xl divide-y divide-white/10">
          <h2
            className="text-2xl font-bold leading-10 tracking-tight text-center text-white"
            id="#FAQ"
          >
            Frequently asked questions
          </h2>
          <dl className="mt-10 space-y-6 divide-y divide-white/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                        <span className="text-base font-semibold leading-7 text-uforange-600">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center text-uforange-600">
                          {open ? (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <span className="text-base leading-7 text-gray-300">
                        {renderRichText(faq.answer, options)}
                      </span>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
